<template>
  <img
    :alt="title"
    :class="className"
    :height="height"
    :src="url"
    :style="styles"
    :title="title"
    :width="width"
  >
</template>
<script>
import imagePlaceholder from "../assets/images/image-placeholder.png";
export default {
  name: "vue-image",
  props: {
    src: null,
    title: null,
    styles: null,
    className: null,
    height: null,
    width: null,
    placeholder: {
      default: imagePlaceholder,
      type: String,
    },
  },
  data() {
    return {
      url: ''
    }
  },
  created() {
    this.url = this.placeholder;
    if (this.src) {
      this.loadImg(this.src);
    }
  },
  methods: {
    loadImg(src) {
      const newImg = new Image();
      newImg.src = src;
      newImg.onerror = () => {
        newImg.src = this.placeholder;
      };
      newImg.onload = () => {
        this.url = newImg.src;
        setTimeout(() => {
          this.$emit('loaded')
        }, 200);
      };
    },
  },
  watch: {
    src: function (val) {
      this.loadImg(val);
    }
  },
}
</script>
