<template>
  <AppHeaderDropdown right class="mr-2">
    <template slot="header">
      <div class="d-none d-sm-inline-block">
        <strong style="color:#fefefe;">{{current_user.username}}</strong>
      </div>

      <img :src="avatar_url" class="img-avatar" />
    </template>\
    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center">
        <strong>Actions</strong>
      </b-dropdown-header>
      <b-dropdown-item to="/servers">
        <i class="icon-list" />Servers
      </b-dropdown-item>
      <b-dropdown-item to="/premium">
        <i class="icon-layers" />Premium
      </b-dropdown-item>
      <b-dropdown-item to="/status">
        <i class="fa fa-heartbeat" />Status
      </b-dropdown-item>
      <b-dropdown-item @click="logout">
        <i class="icon-logout" />Log out
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return {};
  },
  methods: {
    logout() {
      this.axios.get("/api/logout").then(this.$router.push("/"));
      this.$store.commit("logout");
    }
  },
  computed: {
    current_user() {
      return this.$store.state.discord_user || {};
    },
    avatar_url() {
      if (this.current_user.avatar === null) {
        return "https://discordapp.com/assets/1cbd08c76f8af6dddce02c5138971129.png";
      }
      return `https://cdn.discordapp.com/avatars/${this.current_user.id}/${this.current_user.avatar}.png?size=64`;
    },
    current_guild() {
      return this.$store.state.discord_guilds.find(
        x => x.id == this.$route.params.guild_id
      );
    }
  }
};
</script>
