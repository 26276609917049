<template>
  <AppHeaderDropdown class="mr-2" right>
    <template slot="header">
      <strong class="d-sm-down-none" style="color:#fefefe;">{{ current_guild.name }}</strong>
       <vue-image :src="current_guild.icon" className="img-avatar"/>
     </template>
     \
    <template slot="dropdown" >
      <div class="Scroll">
      <b-dropdown-header class="text-center"  tag="div">
        <strong >Jump to</strong>
      </b-dropdown-header>
    
      <div class="scrollable-items">
      <b-dropdown-item
        v-for="g in acceptable_guilds"
        :key="g.id"
        :to="'/dashboard/' + g.id + '/botsettings'"
      >
        <vue-image :src="g.icon" className="img-avatar" height="25" styles="padding: 0" width="25"/>
         <strong class="ml-3">{{ g.name }}</strong>
      </b-dropdown-item>
    </div>
  </div>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
import VueImage from "@/components/VueImage";
export default {
  name: "DefaultHeaderDropdownServer",
  components: {
    AppHeaderDropdown,
    VueImage,
  },
  data: () => {
    return {};
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.axios.get("/api/logout").then(this.$router.push("/"));
    }
  },
  computed: {
    current_user() {
      return this.$store.state.discord_user || {};
    },
    avatar_url() {
      return `https://cdn.discordapp.com/avatars/${this.current_user.id}/${this.current_user.avatar}.png?size=64`;
    },
    current_guild() {
      return this.$store.state.discord_guilds.find(
        x => x.id == this.$route.params.guild_id
      );
    },
    acceptable_guilds() {
      return this.$store.state.discord_guilds.filter(
        x => !x.link && x.id != this.current_guild.id
      );
    }
  }
};
</script>
<style>
.scrollable-items {
  max-height: 50vh; 
  overflow-y: auto; 
  overflow-x: hidden; 
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  opacity: 0; 
}
::-webkit-scrollbar-thumb{
  background: #2d2433; 
  border-radius: 5px;
}
</style>
